import React from 'react';
import { FiMail } from 'react-icons/fi';

import logo from '../images/element-software-logo-name-light.png';

const Header = () => (
  <div className="header">
    <div className="header-inner">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <img src={logo} className="header-logo" alt="Element Software" />
            <p className="lead">Web application development and consulting</p>
            <a href="mailto:info@elementsoftware.ca" className="btn btn-lg btn-outline-light">
              <span className="btn-icon"><FiMail /></span> Get in touch
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Header;
